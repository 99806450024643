import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Послуги тренажерного залу Power club
			</title>
			<meta name={"description"} content={"Пориньте у світ різноманітних пропозицій у Power club Gym, де ми надаємо комплекс послуг, розроблених для будь-якого рівня фізичної підготовки та прагнень."} />
			<meta property={"og:title"} content={"Наші послуги | Послуги тренажерного залу Power club"} />
			<meta property={"og:description"} content={"Пориньте у світ різноманітних пропозицій у Power club Gym, де ми надаємо комплекс послуг, розроблених для будь-якого рівня фізичної підготовки та прагнень."} />
			<meta property={"og:image"} content={"https://kitelsy.com/img/0.jpg"} />
			<link rel={"shortcut icon"} href={"https://kitelsy.com/img/icon 1.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kitelsy.com/img/icon 1.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kitelsy.com/img/icon 1.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kitelsy.com/img/icon 1.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kitelsy.com/img/icon 1.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kitelsy.com/img/icon 1.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" color="--light" padding="80px 0" sm-padding="40px 0">
			<Text
				as="h1"
				font="--headline1"
				color="--primary"
				md-font="--headline2"
				margin="10px 0 0 0"
			>
				Наші Послуги
			</Text>
			<Text
				as="p"
				font="--lead"
				color="--dark"
				margin="40px 0 20px 0"
				text-align="left"
			>
				Пориньте у світ різноманітних пропозицій у Power club Gym, де ми надаємо комплекс послуг, розроблених для будь-якого рівня фізичної підготовки та прагнень. Ось короткий огляд того, що ми пропонуємо:
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="center"
					md-text-align="center"
					sm-text-align="left"
				>
					Послуги
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Персональні тренування
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						- Заняття один на один: Персоналізована увага, яка допоможе вам досягти ваших фітнес-цілей за допомогою індивідуальних планів тренувань.
						<br />
						<br />
						- Дуетні тренування: Об'єднайтеся з другом або партнером для спільного тренування, яке мотивує та кидає виклик.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Групові заняття
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						- Високоенергетичне кардіо: Пропотійте з нашими кардіо-класами, від високоінтенсивних інтервальних тренувань до танцювальних занять.
						<br /><br />
						- Сила та кондиціонування: Розвивайте м'язи та витривалість за допомогою занять, зосереджених на силовому тренуванні та функціональному фітнесі.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Спеціалізовані програми
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						- Молодіжний фітнес: Заохочення до здорових звичок у ранньому віці за допомогою веселих, адаптованих до віку фітнес-занять для молодих людей.
						<br /><br />
						- Сила для людей похилого віку: Індивідуальні програми для підтримки та покращення мобільності, сили та рівноваги для наших літніх членів.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Оздоровчі послуги
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						- Консультування з питань харчування: Експертні поради щодо харчових звичок, які доповнять вашу фітнес-програму та підтримають загальний стан здоров'я.
						<br /><br />
						- Заняття для розуму та тіла: Заняття йогою та пілатесом для покращення гнучкості, сили м'язів та психічного здоров'я.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Послуги
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						- Відкритий доступ до тренажерного залу: Повністю обладнаний тренажерний зал з вільними вагами, тренажерами та кардіотренажерами доступний для всіх членів клубу.
						<br /><br />
						- Чисті та зручні роздягальні: Забезпечення можливості відпочити та розслабитися після тренування в чистому середовищі.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Спільнота та підтримка
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						- Події та воркшопи: Регулярні громадські заходи, присвячені здоров'ю, навчанню та харчуванню.
						<br /><br />
						- Постійна підтримка: Наша команда завжди готова відповісти на запитання, надати поради та підтримати вашу мотивацію.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Готові до змін?
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Зв'яжіться з нами, щоб дізнатися більше про наші послуги або завітайте на екскурсію. Ми тут, щоб підтримати вас на кожному кроці вашого фітнес-подорожі.
				</Text>
				<Link
					href="/contacts"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});